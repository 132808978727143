import { Link, Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Layout } from "antd"; // Import Layout from "antd"
import {
  QueryKeys,
  ROLE_ENUM,
  useAppState,
  useAppTranslation,
} from "../../constants";
import { LogoutIcon } from "../icon";
import { CustomButton } from "../button";
import { getMeAction } from "../../actions";
import { Spacer } from "../spacing";
import "./header.scss";

const { Header } = Layout; // Destructure Header from Layout

export const CustomHeader = () => {
  const { t, i18n } = useAppTranslation();
  const [state, setState] = useAppState({
    language: "en",
  });
  const onChangeLanguageClick = (lng: string) => {
    setState((prevState) => ({ ...prevState, language: lng }));
    i18n.changeLanguage(lng);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    retry: 0,
  });

  if (error) {
    return <Navigate to={"/"} />;
  }

  return (
    <Header
      style={{
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: "0.8",
        position: "relative",
        zIndex: 1,
      }}
    >
      {!isLoading && (
        <Spacer size="middle" direction="horizontal">
          <div style={{ position: "absolute", left: "15px", top: 0 }}>
            <div
              className="language-selector"
              onClick={() =>
                onChangeLanguageClick("en" === state.language ? "de" : "en")
              }
            >
              {`${
                "en" === state.language
                  ? t("header-langugage-en-button")
                  : t("header-langugage-de-button")
              }`}
            </div>
          </div>

          <CustomButton
            loading={isLoading}
            type="default"
            key="admin-link"
            htmlType="button"
            disabled={data?.user?.role?.name !== ROLE_ENUM.SUPER_ADMIN}
          >
            <Link className="button-text" to={"/cms/admins"}>
              {t("admins")}
            </Link>
          </CustomButton>
          <CustomButton
            loading={isLoading}
            type="default"
            key="stat-link"
            htmlType="button"
          >
            <Link className="button-text" to={"/cms"}>{t("statistic")}</Link>
          </CustomButton>
          <div style={{ position: "absolute", right: "15px", top: 0 }}>
            <Spacer size="middle" direction="horizontal">
              <div className="label-text">{t("logged-in-as")}</div>
              <Link className="label-text" style={{ color: "black" }} to={"/cms/profile"}>
                {data.user?.name}
              </Link>
              <LogoutIcon />
            </Spacer>
          </div>
        </Spacer>
      )}
    </Header>
  );
};
