import { createBrowserRouter } from "react-router-dom";
import { AuthHandler } from "../constants";
import { DefaultLayout, LoginLayout } from "../layout";
import {
  AdminsPage,
  ClientPage,
  FirstLoginPage,
  ForgotPasswordConfirmPage,
  ForgotPasswordPage,
  GlobalStatisticsPage,
  LoginPage,
  NotFoundPage,
  ProfilePage,
  SuccessPage,
} from "../pages";

export const router = createBrowserRouter([
  {
    element: (
      <AuthHandler>
        <DefaultLayout />
      </AuthHandler>
    ),
    errorElement: null,
    path: "/cms",
    children: [
      {
        element: <GlobalStatisticsPage />,
        path: "",
      },
      {
        element: <AdminsPage />,
        path: "admins",
      },
      {
        element: <ProfilePage />,
        path: "profile",
      },
      {
        element: <ClientPage />,
        path: "user/:id",
      },
    ],
  },
  {
    element: <LoginLayout />,
    errorElement: null,
    path: "/",
    children: [
      {
        element: <LoginPage />,
        path: `/`,
      },
      {
        element: <FirstLoginPage />,
        path: `first-login`,
      },
      {
        element: <ForgotPasswordPage />,
        path: `forgot-password`,
      },
      {
        element: <ForgotPasswordConfirmPage />,
        path: `forgot-password-confirm`,
      },
    ],
  },
  {
    element: <SuccessPage />,
    path: `/success`,
  },
  {
    element: <NotFoundPage />,
    path: "*",
  },
]);
