import { useQuery } from "@tanstack/react-query";
import { getUserGameDataAction } from "../../../actions";
import { SlideShow } from "../../../components";
import { QueryKeys } from "../../../constants";

type SlideshowProps = {
  id: string | undefined;
};

export function Slideshow({ id }: SlideshowProps) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.clientGame, id],
    queryFn: () => getUserGameDataAction({ id: id }),
  });

  return <div>{!isLoading && <SlideShow games={data.games} />}</div>;
}
