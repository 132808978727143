import { useQuery } from "@tanstack/react-query";
import { HandTitles, QueryKeys, useAppTranslation } from "../../../constants";
import { getClienthandDataAction } from "../../../actions";
import { CustomChart, Spacer } from "../../../components";

type HandChartsProps = {
  id: string | undefined;
};

export function HandCharts({ id }: HandChartsProps) {
  const { t } = useAppTranslation();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.hand, id],
    queryFn: () => getClienthandDataAction({ id }),
  });

  return (
    <div>
      {!isLoading && (
        <Spacer size="large" direction="horizontal">
          <CustomChart
            type="radar"
            chartId="sick-hand"
            dateTimes={HandTitles.map((title) => t(title))}
            minSeriesName=""
            minData={[
              data.sickHand?.closingThumb,
              data.sickHand?.closingIndex,
              data.sickHand?.closingMiddle,
              data.sickHand?.closingRing,
              data.sickHand?.closingPinky,
              data.sickHand?.openingThumb,
              data.sickHand?.openingIndex,
              data.sickHand?.openingMiddle,
              data.sickHand?.openingRing,
              data.sickHand?.openingPinky,
              data.sickHand?.wristSideLeft,
              data.sickHand?.wristSideRight,
              data.sickHand?.rightMax,
              data.sickHand?.leftMax,
            ]}
            width={"450"}
            height={"450"}
            chartTitle={t("sick-hand-chart-title")}
            stepperTitle={""}
            poz="top"
          />
          <CustomChart
            type="radar"
            chartId="healthy-hand"
            dateTimes={HandTitles.map((title) => t(title))}
            minSeriesName=""
            minData={[
              data.healthyHand?.closingThumb,
              data.healthyHand?.closingIndex,
              data.healthyHand?.closingMiddle,
              data.healthyHand?.closingRing,
              data.healthyHand?.closingPinky,
              data.healthyHand?.openingThumb,
              data.healthyHand?.openingIndex,
              data.healthyHand?.openingMiddle,
              data.healthyHand?.openingRing,
              data.healthyHand?.openingPinky,
              data.healthyHand?.wristSideLeft,
              data.healthyHand?.wristSideRight,
              data.healthyHand?.rightMax,
              data.healthyHand?.leftMax,
            ]}
            width={"450"}
            height={"450"}
            chartTitle={t("healthy-hand-chart-title")}
            stepperTitle={""}
            poz="top"
          />
        </Spacer>
      )}
    </div>
  );
}
