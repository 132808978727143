import { Table } from "antd";
import { useAppTranslation } from "../../constants";
import { useCallback } from "react";

type TableProps = {
  columns: Array<any>;
  dataSource: Array<any>;
  onRowClick: (record: any) => any;
  functionDisabler?: boolean;
  scroll: boolean;
};

export function DataTable({
  columns,
  dataSource,
  onRowClick,
  functionDisabler,
  scroll,
}: TableProps) {
  const { t } = useAppTranslation();

  const handleMouseEnter = (e: any) => {
    if (!functionDisabler) {
      e.currentTarget.style.cursor = "pointer";
    }
  };

  const handleRowClick = useCallback(
    (record: any, event: React.MouseEvent<HTMLElement>) => {
      if (!functionDisabler) {
        const target = event.target as HTMLElement;
        const tdElement = target.closest("td");
        if (tdElement) {
          const cellIndex = (tdElement as HTMLTableCellElement).cellIndex;
          const lastColumnIndex = columns.length - 1;
          const isLastColumn = cellIndex === lastColumnIndex;
          if (!isLastColumn) {
            onRowClick(record);
          }
        }
      }
    },
    [onRowClick, functionDisabler, columns.length]
  );

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      locale={{ emptyText: t("no-data") }}
      onRow={(record) => {
        return {
          onClick: (e) => handleRowClick(record, e),
          onMouseEnter: (e) => {
            handleMouseEnter(e);
          },
        };
      }}
      style={{ scrollBehavior: "smooth" }}
      scroll={scroll ? { x: 3000 } : {}}
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
        showSizeChanger: false,
      }}
    />
  );
}
