import { useQuery } from "@tanstack/react-query";
import { QueryKeys, useAppParams, useAppTranslation } from "../../constants";
import { getClientDataAction } from "../../actions";
import { Spacer } from "../../components";
import { ClientTable, HandCharts, Slideshow } from "./components";
import "./client.page.scss";

export function ClientPage() {
  const param = useAppParams();
  const { t } = useAppTranslation();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.oneClient, param?.id],
    queryFn: () => getClientDataAction({ id: param.id }),
  });

  return (
    <main className="client-page">
      <h1>
        <Spacer direction="horizontal" size="middle">
          <>{t("client-page-title")}</>
          <label className="title-text-h1">
            {!isLoading && (data.user?.name ?? data.user?.email)}
          </label>
        </Spacer>
      </h1>
      <HandCharts id={param?.id} />
      <Slideshow id={param?.id} />
      {!isLoading && <ClientTable client={data.user} />}
    </main>
  );
}
